import { AnyOwnership, Ownership, OwnershipType } from "../models/Ownership";
import { HTMLDivElementProps } from "../types/element";
import {
  getOwnershipConnectionStatus,
  getOwnershipStatus,
  OwnershipConnectionStatus,
  OwnershipStatus,
} from "../utils/status";
import React from "react";

interface Props {
  item: AnyOwnership;
}

const emptyText = " ";

export function OwnershipStatusText({ item }: Props & HTMLDivElementProps) {
  switch (item.type) {
    case OwnershipType.Mag:
      return <MagStatusText item={item} />;
    case OwnershipType.Move:
      return <MoveStatusText item={item} />;
    case OwnershipType.Hub:
      return <HubStatusText item={item} />;
    case OwnershipType.Cam:
      return <CamStatusText item={item} />;
    default:
      return <>{emptyText}</>;
  }
}

interface TypeProps<Type extends OwnershipType> {
  item: Ownership<Type>;
}

function MagStatusText({ item }: TypeProps<OwnershipType.Mag>) {
  const status = getOwnershipStatus(item);
  switch (status) {
    case OwnershipStatus.normal:
      return <>閉鎖状態</>;
    case OwnershipStatus.abnormal:
      return <>開放状態</>;
    default:
      return <>不明</>;
  }
}

function MoveStatusText({ item }: TypeProps<OwnershipType.Move>) {
  const status = getOwnershipStatus(item);
  switch (status) {
    case OwnershipStatus.normal:
      return <>{emptyText}</>;
    case OwnershipStatus.abnormal:
      return <>動体を検知</>;
    default:
      return <>不明</>;
  }
}

function HubStatusText({ item }: TypeProps<OwnershipType.Hub>) {
  const status = getOwnershipStatus(item);
  switch (status) {
    case OwnershipStatus.normal:
      return <>{emptyText}</>;
    case OwnershipStatus.abnormal:
      return <>ブザー再生中</>;
    default:
      return <>不明</>;
  }
}

function CamStatusText({ item }: TypeProps<OwnershipType.Cam>) {
  const status = getOwnershipStatus(item);
  const connectionStatus = getOwnershipConnectionStatus(item);
  if (
    status === OwnershipStatus.abnormal &&
    connectionStatus === OwnershipConnectionStatus.connected
  ) {
    return <>動体を検知</>;
  }
  switch (connectionStatus) {
    case OwnershipConnectionStatus.connected:
      return <>オンライン</>;
    case OwnershipConnectionStatus.disconnected:
      return <>オフライン</>;
    default:
      return <>不明</>;
  }
}
