import { HTMLInputElementProps } from "../types/element";
import { IconsExclamationCircle } from "./IconsExclamationCircle";
import classNames from "classnames";
import React, { forwardRef } from "react";
import { get, useFormContext } from "react-hook-form";

interface TextInputProps {
  name: string;
  help?: React.ReactNode;
}

export const TextInput = forwardRef<
  HTMLInputElement,
  TextInputProps & HTMLInputElementProps
>(({ help, ...props }, ref) => {
  const { formState } = useFormContext();
  const error = get(formState.errors, props.name);
  return (
    <div className={classNames(props.className)}>
      <div className="relative">
        <input
          {...props}
          ref={ref}
          className={classNames(
            "block w-full rounded-md border-gray-300 pr-10 placeholder-gray-300 shadow-sm",
            {
              "focus:border-green-500 focus:ring-green-500": !error,
              "border-red-500 ring-red-500 focus:border-red-500 focus:ring-red-500":
                error,
            }
          )}
        />
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          {error ? (
            <IconsExclamationCircle className="h-5 w-5 text-red-500" />
          ) : undefined}
        </div>
      </div>
      {error && <p className="mt-2 text-xs text-red-500">{error.message}</p>}
      {help && <p className="mt-2 text-xs text-gray-500">{help}</p>}
    </div>
  );
});
