import { Token } from "../models/Token";

export function maskToken(token: Token): string {
  if (!token.value) {
    return `********`;
  }
  if (token.value.length === 1) {
    return "*";
  }
  if (token.value.length < 5) {
    return `****${token.value.slice(-(token.value.length - 1))}`;
  }
  return `****${token.value.slice(-4)}`;
}
