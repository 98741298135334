import { Room } from "../models/Room";
import { Token } from "../models/Token";
import { GetRooms } from "../networks/GetRooms";
import { QueryFunction, useQuery } from "@tanstack/react-query";

export function useGetRooms(token: Token) {
  return useQuery<Room[]>(["rooms", token], queryGetRooms(token), {
    suspense: true,
  });
}

function queryGetRooms(token: Token): QueryFunction<Room[]> {
  return async () => {
    if (!token.value) {
      throw new Error("not token");
    }
    const response = await new GetRooms(`${token.value}`).send();
    return response.data;
  };
}
