import { IconsZoomIn } from "../components/IconsZoomIn";
import { IconsZoomOut } from "../components/IconsZoomOut";
import { Loader } from "../components/Loader";
import { useGetOwnership } from "../hooks/useGetOwnership";
import { useGetToken } from "../hooks/useKvsEnvStorage";
import { OwnershipType } from "../models/Ownership";
import LocationMap from "./LocationMap";
import React, { Suspense, useState } from "react";

interface Props {
  serialNumber: string;
}

function Sensor({ serialNumber }: Props) {
  const { data: token } = useGetToken();
  const { data: ownership } = useGetOwnership(serialNumber, { value: token });
  const [zoom, setZoom] = useState<boolean>(false);
  if (!ownership) {
    return null;
  }
  return (
    <div className="space-y-6 pb-16">
      <div>
        <div className="aspect-w-10 aspect-h-10 block w-full overflow-hidden">
          <Suspense fallback={<Loader className="text-gray-400" />}>
            <LocationMap serialNumber={serialNumber} zoom={zoom} />
          </Suspense>
        </div>
        <div className="mt-4 flex items-start justify-between">
          <div>
            <h2 className="text-lg font-medium text-gray-900 dark:text-white">
              <span className="sr-only">Details for </span>
              {ownership.name}
            </h2>
            <p className="text-sm font-medium text-gray-500">
              {ownership.type}
            </p>
          </div>
          <button
            onClick={() => setZoom(!zoom)}
            type="button"
            className="ml-4 flex h-8 w-8 items-center justify-center rounded-full text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none dark:hover:bg-gray-800 dark:hover:text-white"
          >
            {zoom ? (
              <IconsZoomOut className="h-6 w-6" />
            ) : (
              <IconsZoomIn className="h-6 w-6" />
            )}
            <span className="sr-only">ZoomInOut</span>
          </button>
        </div>
      </div>
      <div>
        <h3 className="text-sm font-medium text-gray-900 dark:text-gray-400">
          デバイス情報
        </h3>
        <dl className="mt-2 divide-y divide-gray-200 border-t border-b border-gray-200 dark:divide-gray-600 dark:border-gray-600">
          <div className="flex justify-between py-3 text-sm font-medium">
            <dt className="text-gray-500 dark:text-white">名前</dt>
            <dd className="whitespace-nowrap text-gray-900 dark:text-gray-400">
              {ownership.name}
            </dd>
          </div>
          <div className="flex justify-between py-3 text-sm font-medium">
            <dt className="text-gray-500 dark:text-white">接続状態</dt>
            <dd className="whitespace-nowrap text-gray-900 dark:text-gray-400">
              {ownership.state.connection.status}
            </dd>
          </div>
          {/* TODO: 電池残量の表示をアップデート */}
          {(ownership.type === OwnershipType.Mag ||
            ownership.type === OwnershipType.Move) && (
            <div className="flex justify-between py-3 text-sm font-medium">
              <dt className="text-gray-500 dark:text-white">電池残量</dt>
              <dd className="whitespace-nowrap text-gray-900 dark:text-gray-400">
                {`${ownership.state.gatt?.["180F"]?.["2A19"]?.value}%`}
              </dd>
            </div>
          )}
          <div className="flex justify-between py-3 text-sm font-medium">
            <dt className="text-gray-500 dark:text-white">シリアル番号</dt>
            <dd className="whitespace-nowrap text-gray-900 dark:text-gray-400">
              {ownership.serialNumber}
            </dd>
          </div>
          <div className="flex justify-between py-3 text-sm font-medium">
            <dt className="text-gray-500 dark:text-white">登録アカウント</dt>
            <dd className="whitespace-nowrap text-gray-900 dark:text-gray-400">
              {ownership.userId}
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
}

export default Sensor;
