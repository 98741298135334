export class MissingToken extends Error {
  constructor() {
    super();
    this.message = "APIトークンがこの端末に登録されていません";
  }
}

export class InvalidToken extends Error {
  constructor() {
    super();
    this.message = "不正なAPIトークンです";
  }
}
