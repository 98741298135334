import { HTMLDivElementProps } from "../types/element";
import { Transition } from "@headlessui/react";
import classNames from "classnames";
import React from "react";
import { NavLink } from "react-router-dom";

interface Props {
  show?: boolean | undefined;
  appear?: boolean | undefined;
  onClose: () => void;
}

export function MobileMenu({
  className,
  show,
  onClose,
}: Props & HTMLDivElementProps) {
  return (
    <Transition
      show={show}
      className="relative z-40 md:hidden"
      role="dialog"
      aria-modal="true"
    >
      {/* [Background backdrop] */}
      <Transition.Child
        className="fixed inset-0 bg-black/75 backdrop-blur transition-opacity"
        enter="transition-opacity ease-linear duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity ease-linear duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      />
      <Transition.Child
        className="fixed inset-0 z-40 flex"
        enter="transition ease-in-out duration-300 transform"
        enterFrom="-translate-x-full"
        enterTo="translate-x-0"
        leave="transition ease-in-out duration-300 transform"
        leaveFrom="translate-x-0"
        leaveTo="-translate-x-full"
      >
        <div className="relative flex w-full max-w-xs flex-1 flex-col bg-[#24292f] pt-5 pt-5-safe">
          <Transition.Child
            className="absolute top-1 right-0 -mr-14 p-1 pt-1-safe"
            enter="ease-in-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <button
              onClick={() => onClose()}
              type="button"
              className="flex h-12 w-12 items-center justify-center rounded-full focus:outline-none"
            >
              {/* Heroicon name: outline/x-mark */}
              <svg
                className="h-6 w-6 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
              <span className="sr-only">Close sidebar</span>
            </button>
          </Transition.Child>
          <div className="flex flex-shrink-0 items-center px-4">
            <svg
              className="h-8 w-auto"
              width="47"
              height="40"
              viewBox="0 0 47 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.5 4H23.5C32.3371 4 39.5 11.1623 39.5 20C39.5 28.8371 32.3377 36 23.5 36H22.1618C21.7963 36 21.5 35.7037 21.5 35.3382V32.6618C21.5 32.2963 21.7963 32 22.1618 32H23.5C29.447 32 34.3822 27.6758 35.3341 22H23.5C14.6623 22 7.5 14.8371 7.5 6V4H8H10H11.5Z"
                fill="white"
              />
            </svg>
          </div>
          <div className="mt-5 h-0 flex-1 overflow-y-auto px-2">
            <nav className="flex h-full flex-col">
              <div className="space-y-1">
                {/* Current: "bg-gray-700 text-white", Default: "text-indigo-100 hover:bg-indigo-800 hover:text-white" */}
                <NavLink
                  to="/"
                  onClick={() => onClose()}
                  className={({ isActive }) =>
                    classNames(
                      "group flex items-center rounded-md py-2 px-3 text-sm font-medium text-white",
                      {
                        "bg-gray-700": isActive,
                        "text-indigo-100 hover:bg-gray-900 hover:text-white":
                          !isActive,
                      }
                    )
                  }
                >
                  {({ isActive }) => (
                    <>
                      <svg
                        className={classNames("mr-3 h-6 w-6", {
                          "text-white": isActive,
                          "text-white group-hover:text-white": !isActive,
                        })}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                        />
                      </svg>
                      <span>ホーム</span>
                    </>
                  )}
                </NavLink>
                <NavLink
                  to="/settings"
                  onClick={() => onClose()}
                  className={({ isActive }) =>
                    classNames(
                      "group flex items-center rounded-md py-2 px-3 text-sm font-medium text-white",
                      {
                        "bg-gray-700": isActive,
                        "text-indigo-100 hover:bg-gray-900 hover:text-white":
                          !isActive,
                      }
                    )
                  }
                >
                  {({ isActive }) => (
                    <>
                      <svg
                        className={classNames("mr-3 h-6 w-6", {
                          "text-white": isActive,
                          "text-white group-hover:text-white": !isActive,
                        })}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M4.5 12a7.5 7.5 0 0015 0m-15 0a7.5 7.5 0 1115 0m-15 0H3m16.5 0H21m-1.5 0H12m-8.457 3.077l1.41-.513m14.095-5.13l1.41-.513M5.106 17.785l1.15-.964m11.49-9.642l1.149-.964M7.501 19.795l.75-1.3m7.5-12.99l.75-1.3m-6.063 16.658l.26-1.477m2.605-14.772l.26-1.477m0 17.726l-.26-1.477M10.698 4.614l-.26-1.477M16.5 19.794l-.75-1.299M7.5 4.205L12 12m6.894 5.785l-1.149-.964M6.256 7.178l-1.15-.964m15.352 8.864l-1.41-.513M4.954 9.435l-1.41-.514M12.002 12l-3.75 6.495"
                        />
                      </svg>
                      <span>設定</span>
                    </>
                  )}
                </NavLink>
              </div>
            </nav>
          </div>
          <div className="flex flex-shrink-0 border-t border-gray-600 p-4 pb-4-safe">
            <a href="#" className="group block flex-shrink-0">
              <div className="flex items-center">
                <div />
                <div className="ml-3">
                  <p className="text-sm font-medium text-gray-400">
                    バージョン {process.env.REACT_APP_VERSION}
                  </p>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div className="w-14 flex-[0.5]" aria-hidden="true" onClick={onClose} />
      </Transition.Child>
    </Transition>
  );
}
