import { HTMLDivElementProps } from "../types/element";
import { IconsOutlineXMark } from "./IconsOutlineXMark";
import { Transition } from "@headlessui/react";
import classNames from "classnames";
import React from "react";
import ReactDOM from "react-dom";

interface Props {
  open: boolean;
  onClose?: (e: unknown) => void;
}

/*
  [SlideOvers]
  url: https://tailwindui.com/components/application-ui/overlays/slide-overs#component-62a04be4dcfb133783a1a2b1774a73fa
 */
export function SlideOver({
  open,
  className,
  children,
  onClose,
}: Props & HTMLDivElementProps) {
  return ReactDOM.createPortal(
    <Transition
      show={open}
      className={classNames("safe-areas relative z-10", className)}
    >
      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          {/* [Background backdrop] */}
          <Transition.Child
            className="fixed inset-0 bg-black/75 backdrop-blur transition-opacity"
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            onClick={onClose}
          />
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            {/* [Slide-over panel] */}
            <Transition.Child
              className="pointer-events-auto relative w-96"
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              {/* [Close button] */}
              <Transition.Child
                className="absolute top-0 left-0 -ml-8 flex pt-4 pr-2 pt-4-safe sm:-ml-10 sm:pr-4"
                enter="ease-in-out duration-500"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-500"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <button
                  type="button"
                  className="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                  onClick={onClose}
                >
                  <span className="sr-only">Close panel</span>
                  {/* Heroicon name: outline/x-mark */}
                  <IconsOutlineXMark className="h-6 w-6" />
                </button>
              </Transition.Child>
              {/* [Slide-over panel, show/hide based on slide-over state.] */}
              <div className="h-full overflow-y-auto bg-white p-8 pt-8-safe dark:bg-[#1B1D21]">
                {children}
              </div>
            </Transition.Child>
          </div>
        </div>
      </div>
    </Transition>,
    document.getElementById("slide-overs")!
  );
}
