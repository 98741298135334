import { AnyOwnership } from "../models/Ownership";
import { refetchInterval } from "../utils/delay";
import { Request } from "./Request";
import { Method } from "axios";

export interface OwnershipsResponse {
  timestamp: number;
  userIds: number[];
  ownerships: AnyOwnership[];
  next: number;
}

export class GetOwnerships extends Request<OwnershipsResponse> {
  method = "get" as Method;

  constructor(token: string) {
    super();
    this.headers = {
      "X-Token": token,
    };
    this.baseUrl = process.env.REACT_APP_API_BASE_URL;
    this.path = `v1/ownerships`;
    this.data = {};
  }

  parse(response: any) {
    return {
      ...response.data,
      next: Date.now() + refetchInterval,
    };
  }
}
