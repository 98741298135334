import { ErrorMessageField } from "../components/ErrorMessageField";
import { TextInput } from "../components/TextInput";
import { useSetToken } from "../hooks/useKvsEnvStorage";
import { ErrorMessage } from "@hookform/error-message";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";

function Gateway() {
  const methods = useForm();
  const { mutateAsync } = useSetToken();
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
    setError,
    clearErrors,
  } = methods;
  return (
    <div className="flex h-full min-h-screen">
      <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div className="text-leafee-green">
            <svg
              className="h-12 w-auto"
              width="47"
              height="40"
              viewBox="0 0 47 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.5 4H23.5C32.3371 4 39.5 11.1623 39.5 20C39.5 28.8371 32.3377 36 23.5 36H22.1618C21.7963 36 21.5 35.7037 21.5 35.3382V32.6618C21.5 32.2963 21.7963 32 22.1618 32H23.5C29.447 32 34.3822 27.6758 35.3341 22H23.5C14.6623 22 7.5 14.8371 7.5 6V4H8H10H11.5Z"
                fill="currentColor"
              />
            </svg>
          </div>
          <div className="mt-8">
            <FormProvider {...methods}>
              <form
                className="space-y-6"
                onSubmit={(e) => {
                  clearErrors("server");
                  handleSubmit(onSubmit)(e);
                }}
              >
                <div>
                  <ErrorMessage
                    errors={errors}
                    name="server"
                    render={({ message }) => (
                      <ErrorMessageField className="mb-6">
                        {message}
                      </ErrorMessageField>
                    )}
                  />
                  <label
                    htmlFor="token"
                    className="block text-sm font-medium text-gray-700 dark:text-white"
                  >
                    あなたのAPIトークン
                  </label>
                  <div className="mt-1">
                    <TextInput
                      id="token"
                      type="password"
                      placeholder="トークン文字列を入力..."
                      {...register("token", {
                        required: "トークンを入力してください",
                      })}
                    />
                  </div>
                </div>
                <div>
                  <button
                    type="submit"
                    className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    {isSubmitting ? (
                      <svg
                        className="h-5 w-5 animate-spin text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        />
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        />
                      </svg>
                    ) : (
                      <>端末に登録して開始</>
                    )}
                  </button>
                </div>
              </form>
            </FormProvider>
          </div>
        </div>
      </div>
      <div className="relative hidden w-0 flex-1 lg:block">
        <img
          className="absolute inset-0 h-full w-full object-cover"
          src="https://images.unsplash.com/photo-1517502884422-41eaead166d4?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
          alt="オフィスの画像イメージ"
        />
      </div>
    </div>
  );

  async function onSubmit(values: any) {
    try {
      await mutateAsync(values?.token);
      window.location.reload();
    } catch (error: any) {
      window.scrollTo(0, 0);
      console.log(error);
      return setError("server", {
        type: "manual",
        message: error.message,
      });
    }
  }
}

export default Gateway;
