import { HTMLDivElementProps } from "../types/element";
import classNames from "classnames";
import React from "react";

interface Props {}

export function BottomNavigation({
  className,
  children,
}: Props & HTMLDivElementProps) {
  return (
    <div
      className={classNames(
        "fixed inset-x-0 bottom-0 z-10 bg-white pb-safe dark:bg-[#1B1D21] md:left-28",
        className
      )}
    >
      <div className="w-full border-t dark:border-t-[#303235]">
        <div className="mx-auto flex max-w-7xl justify-between p-5 sm:px-6 lg:px-8">
          {children}
        </div>
      </div>
    </div>
  );
}
