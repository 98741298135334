import { refetchInterval } from "../utils/delay";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";

dayjs.extend(duration);

export function useTimer(target?: number) {
  return useQuery<{ percent: number; label: string }>(
    [`timer`],
    () => createStopwatch(target),
    {
      refetchInterval: 1000 / 30, // FPS is 30
      retry: false,
      cacheTime: 0,
      staleTime: 0,
    }
  );
}

function createStopwatch(target?: number) {
  if (!target) {
    return {
      percent: 0,
      label: "",
    };
  }
  const now = dayjs();
  const endTime = dayjs(target);
  const remain = dayjs.duration(endTime.diff(now)).asMilliseconds();
  const r = remain > 0 ? remain : refetchInterval;
  return {
    percent: (refetchInterval - r) / refetchInterval,
    label: `${Math.ceil(r / 1000)}`,
  };
}
