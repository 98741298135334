import { useGetLocation } from "../hooks/useGetLocation";
import { useGetOwnership } from "../hooks/useGetOwnership";
import { useGetRoom } from "../hooks/useGetRoom";
import { useGetToken } from "../hooks/useKvsEnvStorage";
import {
  getOwnershipConnectionStatus,
  getOwnershipStatus,
  OwnershipConnectionStatus,
  OwnershipStatus,
} from "../utils/status";
import classNames from "classnames";
import React from "react";

interface Props {
  serialNumber: string;
  zoom: boolean;
}

enum Background {
  green,
  red,
  gray,
}

function LocationMap({ serialNumber, zoom }: Props) {
  const { data: token } = useGetToken();
  const { data: ownership } = useGetOwnership(
    serialNumber,
    { value: token },
    1000 / 30
  );
  const { data: room } = useGetRoom(serialNumber, { value: token });
  const { data: location } = useGetLocation(serialNumber, { value: token });
  const bg = getBackground();
  return location && room?.image ? (
    <div
      className={classNames("h-[200%] w-[200%] origin-top-left scale-[0.5]")}
    >
      <div
        className={classNames(
          "aspect-w-10 aspect-h-10 transform transition duration-200 ease-in-out",
          {
            "scale-[1]": !zoom,
            "scale-[2]": zoom,
          }
        )}
        style={{
          transformOrigin: `${location.x}% ${location.y}%`,
        }}
      >
        <span
          className="absolute z-10 flex h-6 w-6"
          style={{
            top: `${location.y}%`,
            left: `${location.x}%`,
          }}
        >
          <span
            className={classNames(
              "absolute inline-flex h-full w-full animate-ping rounded-full opacity-75",
              {
                "bg-leafee-green": bg === Background.green,
                "bg-leafee-orange": bg === Background.red,
                "bg-[#757575] dark:bg-[#FAFAFA]": bg === Background.gray,
              }
            )}
          />
          <span
            className={classNames("relative inline-flex h-6 w-6 rounded-full", {
              "bg-leafee-green": bg === Background.green,
              "bg-leafee-orange": bg === Background.red,
              "bg-[#757575] dark:bg-[#9e9e9e]": bg === Background.gray,
            })}
          />
        </span>
        <img
          src={`data:image/svg+xml;base64,${room?.image}`}
          alt=""
          className="object-cover"
        />
      </div>
    </div>
  ) : (
    <div>No Location</div>
  );

  function getBackground(): Background {
    if (!ownership) {
      return Background.gray;
    }
    const status = getOwnershipConnectionStatus(ownership);
    if (status !== OwnershipConnectionStatus.connected) {
      return Background.gray;
    }
    switch (getOwnershipStatus(ownership)) {
      case OwnershipStatus.normal:
        return Background.green;
      case OwnershipStatus.abnormal:
        return Background.red;
      case OwnershipStatus.unknown:
        return Background.gray;
    }
  }
}

export default LocationMap;
