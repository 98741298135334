import { MissingToken } from "../errors";
import { Token } from "../models/Token";
import { GetOwnerships } from "../networks/GetOwnerships";
import { GetRooms } from "../networks/GetRooms";
import { delay } from "../utils/delay";
import { kvsEnvStorage } from "@kvs/env";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

export function useGetToken() {
  return useQuery<string | undefined>(
    ["kvs", "token", "value"],
    async () => {
      console.log("useGetToken start!");
      const storage = await kvsEnvStorage<Token>({
        name: "token",
        version: 1,
      });
      const cache = await storage.get("value");
      console.log("useGetToken cache", cache);
      if (!cache) {
        throw new MissingToken();
      }
      return cache;
    },
    // NOTE:- This function retrieves the token value from local storage, so there is no need to retry.
    {
      retry: false,
      suspense: true,
    }
  );
}

export function useSetToken() {
  const queryClient = useQueryClient();
  return useMutation(
    async (value: string) => {
      const storage = await kvsEnvStorage<Token>({
        name: "token",
        version: 1,
      });
      const [ownerships, rooms] = await Promise.all([
        new GetOwnerships(value).send(),
        new GetRooms(value).send(),
      ]);
      await Promise.all([storage.set("value", value), delay(1500)]);
      queryClient.setQueryData(["ownerships"], ownerships);
      queryClient.setQueryData(["rooms"], rooms.data);
      queryClient.setQueryData(["kvs", "token", "value"], value);
      return value;
    },
    {
      onSuccess: async (value) => {
        console.log("value", value);
      },
      onError: (error) => {
        console.error("ERROR", error);
      },
    }
  );
}
