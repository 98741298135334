import { AnyOwnership } from "../models/Ownership";
import { Token } from "../models/Token";
import { useGetOwnerships } from "./useGetOwnerships";
import { useQuery } from "@tanstack/react-query";

export function useGetOwnership(
  serialNumber: string,
  token: Token,
  fps?: number
) {
  const { data } = useGetOwnerships(token);
  return useQuery<AnyOwnership | null>(
    ["ownership", serialNumber, token],
    () =>
      data?.ownerships.find(
        (ownership) => ownership.serialNumber === serialNumber
      ) ?? null,
    {
      suspense: true,
      enabled: !!data,
      refetchInterval: fps,
    }
  );
}
