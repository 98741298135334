export enum OwnershipType {
  Mag = "mag",
  Move = "move",
  Cam = "cam",
  Hub = "hub",
}

export type ConnectionStatus = string | null;

export type OwnershipState<Type extends OwnershipType> =
  Type extends OwnershipType.Mag
    ? MagState
    : Type extends OwnershipType.Move
    ? MoveState
    : Type extends OwnershipType.Cam
    ? CamState
    : Type extends OwnershipType.Hub
    ? HubState
    : never;

export interface Ownership<Type extends OwnershipType> {
  userId: number;
  serialNumber: string;
  name: string;
  type: Type;
  state: OwnershipState<Type>;
}

export type AnyOwnership =
  | Ownership<OwnershipType.Mag>
  | Ownership<OwnershipType.Move>
  | Ownership<OwnershipType.Cam>
  | Ownership<OwnershipType.Hub>;

export interface HubState {
  connection: {
    status: ConnectionStatus;
  };
  hat: {
    buzzer: {
      value: boolean | null;
      timestamp: number | null; // sec
    };
  };
}

export interface MagState {
  connection: {
    status: ConnectionStatus;
  };
  gatt: {
    "180F": {
      "2A19": {
        value: number | null; // 0-100
        timestamp: number | null; // sec
      };
    };
    "strobo:1002": {
      "3000": {
        value: boolean | null;
        timestamp: number | null; // sec
      };
    };
  };
}

export interface MoveState {
  connection: {
    status: ConnectionStatus;
  };
  gatt: {
    "180F": {
      "2A19": {
        value: number | null; // 0-100
        timestamp: number | null; // sec
      };
    };
    "strobo:1002": {
      "3008": {
        value: boolean | null;
        timestamp: number | null; // sec
      };
    };
  };
}

export interface CamState {
  connection: {
    status: ConnectionStatus;
  };
  functions: {
    movementDetectPic: {
      value: string | null;
      timestamp: number | null; // sec
    };
  };
}
