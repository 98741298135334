import { Header } from "../components/Header";
import { useGetToken, useSetToken } from "../hooks/useKvsEnvStorage";
import { Transition, Dialog } from "@headlessui/react";
import React, { Fragment, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { maskToken } from "../utils/mask";
import { ErrorMessageField } from "../components/ErrorMessageField";
import { ErrorMessage } from "@hookform/error-message";

function Settings() {
  const methods = useForm();
  const { data: token } = useGetToken();
  const { mutateAsync } = useSetToken();
  const [editing, setEditing] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    setError,
    formState: { isSubmitting, errors },
    clearErrors,
  } = methods;
  return (
    <div className="flex flex-1 flex-col overflow-hidden">
      {/* Content area */}
      <Header />
      {/* Main content */}
      <div className="flex flex-1 items-stretch overflow-hidden">
        <main className="flex-1 overflow-y-auto">
          {/* Headings And Tabs */}
          <div className="mx-auto w-full max-w-7xl overflow-y-auto px-4 sm:px-6 lg:px-8">
            {/* Headings */}
            <div className="flex pt-8">
              <h1 className="flex-1 text-2xl font-bold text-gray-900 dark:text-white">
                設定
              </h1>
            </div>
          </div>
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="mt-10 divide-y divide-gray-200 dark:divide-gray-600">
              <div className="space-y-1">
                <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-white">
                  アプリの利用設定
                </h3>
                <p className="max-w-2xl text-sm text-gray-500">
                  ホーム画面などに情報を表示するためのアプリの設定を行います。
                </p>
              </div>
              <div className="mt-6">
                <dl className="divide-y divide-gray-200">
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                    <dt className="text-sm font-medium text-gray-500 dark:text-white">
                      APIトークン
                    </dt>
                    <dd className="mt-1 flex text-sm text-gray-900 dark:text-gray-500 sm:col-span-2 sm:mt-0">
                      <span className="flex-grow">
                        {token ? maskToken({ value: token }) : "未設定"}
                      </span>
                      <span className="ml-4 flex-shrink-0">
                        <button
                          type="button"
                          onClick={() => setEditing(true)}
                          className="rounded-md font-medium text-purple-600 hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                        >
                          更新
                        </button>
                      </span>
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </main>
      </div>
      <Transition as={Fragment} show={editing}>
        <Dialog className="relative z-10" onClose={() => setEditing(false)}>
          {/* The backdrop, rendered as a fixed sibling to the panel container */}
          {/*
                    Background backdrop, show/hide based on modal state.

                    Entering: "ease-out duration-300"
                      From: "opacity-0"
                      To: "opacity-100"
                    Leaving: "ease-in duration-200"
                      From: "opacity-100"
                      To: "opacity-0"
                 */}
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              {/*
                                Modal panel, show/hide based on modal state.

                                Entering: "ease-out duration-300"
                                From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                To: "opacity-100 translate-y-0 sm:scale-100"
                                Leaving: "ease-in duration-200"
                                From: "opacity-100 translate-y-0 sm:scale-100"
                                To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            */}
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <Dialog.Panel className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                        {/* Heroicon name: outline/exclamation-triangle */}
                        <svg
                          className="h-6 w-6 text-red-600"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 10.5v3.75m-9.303 3.376C1.83 19.126 2.914 21 4.645 21h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 4.88c-.866-1.501-3.032-1.501-3.898 0L2.697 17.626zM12 17.25h.007v.008H12v-.008z"
                          />
                        </svg>
                      </div>
                      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <Dialog.Title className="text-lg font-medium leading-6 text-gray-900">
                          APIトークンを更新
                        </Dialog.Title>
                        <div className="mt-2">
                          <Dialog.Description className="text-sm text-gray-500">
                            このビューワをご利用頂くために必要なAPIトークンを設定・更新することが出来ます。APIトークンが不明な場合は、お手数おかけしますが営業担当者までお問い合わせください。
                          </Dialog.Description>
                          <ErrorMessage
                            errors={errors}
                            name="server"
                            render={({ message }) => (
                              <ErrorMessageField className="my-3">
                                {message}
                              </ErrorMessageField>
                            )}
                          />
                        </div>
                        <FormProvider {...methods}>
                          <form
                            className="mt-5 sm:flex sm:items-center"
                            onSubmit={(e) => {
                              clearErrors("server");
                              handleSubmit(onSubmit)(e);
                            }}
                          >
                            <div className="flex-1">
                              <label htmlFor="token" className="sr-only">
                                Token
                              </label>
                              <input
                                id="token"
                                {...register("token", {
                                  required: "トークンを入力してください",
                                })}
                                type="text"
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                placeholder="あなたのAPIトークン"
                              />
                            </div>
                            <button
                              type="submit"
                              className="mt-3 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                            >
                              {isSubmitting ? (
                                <svg
                                  className="h-5 w-5 animate-spin text-white"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                >
                                  <circle
                                    className="opacity-25"
                                    cx="12"
                                    cy="12"
                                    r="10"
                                    stroke="currentColor"
                                    strokeWidth="4"
                                  />
                                  <path
                                    className="opacity-75"
                                    fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                  />
                                </svg>
                              ) : (
                                <>更新</>
                              )}
                            </button>
                          </form>
                        </FormProvider>
                      </div>
                    </div>
                  </Dialog.Panel>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <button
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={() => setEditing(false)}
                    >
                      閉じる
                    </button>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );

  async function onSubmit(values: any) {
    try {
      await mutateAsync(values?.token);
      setEditing(false);
    } catch (error: any) {
      console.error(error);
      return setError("server", {
        type: "manual",
        message: error.message,
      });
    }
  }
}

export default Settings;
