import { HTMLDivElementProps } from "../types/element";
import classNames from "classnames";
import React from "react";

interface Props {}

export function Loader({ className }: Props & HTMLDivElementProps) {
  return (
    <div
      className={classNames(
        "absolute inset-0 z-10 flex items-center justify-center",
        className
      )}
    >
      <svg
        width="38"
        height="38"
        viewBox="0 0 18 18"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          transform="translate(1 1)"
          stroke="currentColor"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <circle strokeOpacity=".5" cx="8" cy="8" r="8" />
          <path d="M16 8a8 8 0 00-8-8">
            <animateTransform
              attributeName="transform"
              type="rotate"
              from="0 8 8"
              to="360 8 8"
              dur="1s"
              repeatCount="indefinite"
            />
          </path>
        </g>
      </svg>
    </div>
  );
}
