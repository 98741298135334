import { Room } from "../models/Room";
import { Request } from "./Request";
import { Method } from "axios";

export interface RoomsResponse {
  data: Room[];
}

export class GetRooms extends Request<RoomsResponse> {
  method = "get" as Method;

  constructor(token: string) {
    super();
    this.headers = {
      "X-Token": token,
    };
    this.baseUrl = process.env.REACT_APP_API_BASE_URL;
    this.path = `v1/rooms`;
    this.data = {};
  }

  parse(response: any) {
    return {
      data: response.data,
    };
  }
}
