import { Room } from "../models/Room";
import { Token } from "../models/Token";
import { useGetRooms } from "./useGetRooms";
import { useQuery } from "@tanstack/react-query";

export function useGetRoom(serialNumber: string, token: Token) {
  const { data: rooms } = useGetRooms(token);
  return useQuery<Room | null>(
    ["room", serialNumber, token],
    () =>
      rooms?.find((room) =>
        room.locations.some(
          (location) => location.serialNumber === serialNumber
        )
      ) ?? null,
    {
      suspense: true,
      enabled: !!rooms,
    }
  );
}
