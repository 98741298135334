import { Location } from "../models/Location";
import { Token } from "../models/Token";
import { useGetRoom } from "./useGetRoom";
import { useQuery } from "@tanstack/react-query";

export function useGetLocation(serialNumber: string, token: Token) {
  const { data: room } = useGetRoom(serialNumber, token);
  return useQuery<Location | null>(
    ["location", serialNumber, token],
    () =>
      room?.locations.find(
        (location) => location.serialNumber === serialNumber
      ) ?? null,
    {
      suspense: true,
      enabled: !!room,
    }
  );
}
