import { Loader } from "./components/Loader";
import { MobileMenu } from "./components/MobileMenu";
import { SideBar } from "./components/SideBar";
import { MissingToken } from "./errors";
import Gateway from "./pages/Gateway";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React, { useState, Suspense } from "react";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import { Outlet } from "react-router-dom";

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      networkMode: "always",
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchIntervalInBackground: false,
    },
  },
});

function App() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  return (
    <QueryClientProvider client={queryClient}>
      <ErrorBoundary
        fallbackRender={({ error }: FallbackProps) => {
          // NOTE:- トークンが存在しない場合は、ゲートウェイページに遷移します。
          if (error instanceof MissingToken) {
            return <Gateway />;
          }
          return <h1>Error</h1>;
        }}
      >
        <div className="safe-areas flex h-full min-h-screen">
          <SideBar />
          <MobileMenu
            show={isSidebarOpen}
            onClose={() => setIsSidebarOpen(false)}
          />
          <Suspense fallback={<Loader className="text-green-500" />}>
            <Outlet context={[isSidebarOpen, setIsSidebarOpen]} />
          </Suspense>
        </div>
      </ErrorBoundary>
    </QueryClientProvider>
  );
}

export default App;
