import { AnyOwnership } from "../models/Ownership";
import { Room } from "../models/Room";
import groupby from "lodash.groupby";

export interface GroupedByUserIdOwnerships {
  userId: number;
  ownerships: AnyOwnership[];
}

export function groupByUserId(
  ownerships?: AnyOwnership[]
): GroupedByUserIdOwnerships[] {
  return Object.entries(
    groupby(ownerships, (ownership) => ownership.userId)
  ).map(([key, value], index) => {
    return {
      userId: +key,
      ownerships: value,
    };
  });
}

export interface GroupedByRoomOwnerships {
  room: Room;
  ownerships: AnyOwnership[];
}

export const unknownRoom: Room = {
  name: "所属なし",
  image: "",
  locations: [],
};

export function groupByRoom(
  ownerships: AnyOwnership[] = [],
  rooms: Room[] = []
): GroupedByRoomOwnerships[] {
  const ownershipMap = new Map(
    ownerships.map((ownership) => [ownership.serialNumber, ownership])
  );

  const ownershipsMap = new Map<Room, AnyOwnership[]>();
  for (const room of rooms) {
    const list: AnyOwnership[] = [];
    ownershipsMap.set(room, list);
    for (const { serialNumber } of room.locations) {
      const ownership = ownershipMap.get(serialNumber);
      if (!ownership) {
        continue;
      }

      ownershipMap.delete(serialNumber);
      list.push(ownership);
    }
  }

  ownershipsMap.set(unknownRoom, Array.from(ownershipMap.values()));

  return Array.from(ownershipsMap, ([room, ownerships]) => ({
    room,
    ownerships,
  }));
}
