import { AnyOwnership, OwnershipType } from "../models/Ownership";
import { HTMLDivElementProps } from "../types/element";
import React from "react";

interface OwnershipProps {
  item: AnyOwnership;
}

export function OwnershipIcon({
  className,
  item,
}: OwnershipProps & HTMLDivElementProps) {
  switch (item.type) {
    case OwnershipType.Mag:
      return (
        <svg
          className={className}
          width="28"
          height="20"
          viewBox="0 0 28 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M3 4C3 2.34315 4.34315 1 6 1H18C19.6569 1 21 2.34315 21 4V16C21 17.6569 19.6569 19 18 19H6C4.34315 19 3 17.6569 3 16V4ZM22 5C22 4.44772 22.4477 4 23 4H25C25.5523 4 26 4.44772 26 5V15C26 15.5523 25.5523 16 25 16H23C22.4477 16 22 15.5523 22 15V5ZM18.5 8C18.2239 8 18 8.22386 18 8.5V10.5C18 10.7761 18.2239 11 18.5 11C18.7761 11 19 10.7761 19 10.5V8.5C19 8.22386 18.7761 8 18.5 8Z"
              fill="black"
            />
          </g>
        </svg>
      );
    case OwnershipType.Move:
      return (
        <svg
          className={className}
          width="28"
          height="20"
          viewBox="0 0 28 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.5 2C9.5 0.895431 10.3954 0 11.5 0H16.5C17.6046 0 18.5 0.895431 18.5 2V18C18.5 19.1046 17.6046 20 16.5 20H11.5C10.3954 20 9.5 19.1046 9.5 18V2ZM14 7C15.1046 7 16 6.10457 16 5C16 3.89543 15.1046 3 14 3C12.8954 3 12 3.89543 12 5C12 6.10457 12.8954 7 14 7ZM14 13C13.7239 13 13.5 13.2239 13.5 13.5V15.5C13.5 15.7761 13.7239 16 14 16C14.2761 16 14.5 15.7761 14.5 15.5V13.5C14.5 13.2239 14.2761 13 14 13Z"
              fill="black"
            />
            <path
              opacity="0.3"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M14 7C15.1046 7 16 6.10457 16 5C16 3.89543 15.1046 3 14 3C12.8954 3 12 3.89543 12 5C12 6.10457 12.8954 7 14 7Z"
              fill="black"
            />
          </g>
        </svg>
      );
    case OwnershipType.Hub:
      return (
        <svg
          className={className}
          width="28"
          height="20"
          viewBox="0 0 28 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g>
            <path
              opacity="0.6"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7 0C5.34315 0 4 1.34315 4 3V17C4 18.6569 5.34315 20 7 20H21C22.6569 20 24 18.6569 24 17V3C24 1.34315 22.6569 0 21 0H7ZM7.5 1C6.11929 1 5 2.11929 5 3.5V10H23V3.5C23 2.11929 21.8807 1 20.5 1H7.5Z"
              fill="black"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5 3.5C5 2.11929 6.11929 1 7.5 1H20.5C21.8807 1 23 2.11929 23 3.5V10H5V3.5ZM12.5 4.5C12.5 3.67157 13.1716 3 14 3C14.8284 3 15.5 3.67157 15.5 4.5C15.5 5.32843 14.8284 6 14 6C13.1716 6 12.5 5.32843 12.5 4.5ZM12.5 7C12.2239 7 12 7.22386 12 7.5C12 7.77614 12.2239 8 12.5 8H15.5C15.7761 8 16 7.77614 16 7.5C16 7.22386 15.7761 7 15.5 7H12.5Z"
              fill="black"
            />
            <rect
              opacity="0.6"
              x="12"
              y="7"
              width="4"
              height="1"
              rx="0.5"
              fill="black"
            />
          </g>
        </svg>
      );
    case OwnershipType.Cam:
      return (
        <svg
          className={className}
          width="28"
          height="20"
          viewBox="0 0 28 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g>
            <path
              d="M6 14H22V16C22 18.2091 20.2091 20 18 20H14H10C7.79086 20 6 18.2091 6 16V14Z"
              fill="black"
              fillOpacity="0.6"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M14 0C9.58172 0 6 3.58172 6 8V13.6829C6 15.5149 7.4851 17 9.31707 17H18.6829C20.5149 17 22 15.5149 22 13.6829V8C22 3.58172 18.4183 0 14 0ZM16 7C16 8.10457 15.1046 9 14 9C12.8954 9 12 8.10457 12 7C12 5.89543 12.8954 5 14 5C15.1046 5 16 5.89543 16 7ZM18 7C18 9.20914 16.2091 11 14 11C11.7909 11 10 9.20914 10 7C10 4.79086 11.7909 3 14 3C16.2091 3 18 4.79086 18 7Z"
              fill="black"
            />
          </g>
        </svg>
      );
    default:
      return null;
  }
}
