import { HTMLDivElementProps } from "../types/element";
import classNames from "classnames";
import React from "react";
import { NavLink } from "react-router-dom";

interface Props {}

export function SideBar({ className }: Props & HTMLDivElementProps) {
  return (
    <div
      className={classNames(
        "hidden w-28 overflow-y-auto bg-[#24292f] md:block",
        className
      )}
    >
      <div className="flex w-full flex-col items-center py-6">
        <div className="flex flex-shrink-0 items-center">
          <svg
            className="h-8 w-auto"
            width="47"
            height="40"
            viewBox="0 0 47 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11.5 4H23.5C32.3371 4 39.5 11.1623 39.5 20C39.5 28.8371 32.3377 36 23.5 36H22.1618C21.7963 36 21.5 35.7037 21.5 35.3382V32.6618C21.5 32.2963 21.7963 32 22.1618 32H23.5C29.447 32 34.3822 27.6758 35.3341 22H23.5C14.6623 22 7.5 14.8371 7.5 6V4H8H10H11.5Z"
              fill="white"
            />
          </svg>
        </div>
        <div className="mt-6 w-full flex-1 space-y-1 px-2">
          {/* HOME */}
          <NavLink
            to="/"
            className={({ isActive }) =>
              classNames(
                "group flex w-full flex-col items-center rounded-md p-3 text-xs font-medium text-white",
                {
                  "bg-gray-700": isActive,
                  "hover:bg-gray-900 hover:text-white": !isActive,
                }
              )
            }
          >
            {({ isActive }) => (
              <>
                <svg
                  className={classNames("h-6 w-6 group-hover:text-white", {
                    "text-white": isActive,
                    "": !isActive,
                  })}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                  />
                </svg>
                <span className="mt-2">ホーム</span>
              </>
            )}
          </NavLink>
          {/* SETTINGS */}
          <NavLink
            to="/settings"
            className={({ isActive }) =>
              classNames(
                "group flex w-full flex-col items-center rounded-md p-3 text-xs font-medium text-white",
                {
                  "bg-gray-700": isActive,
                  "hover:bg-gray-900 hover:text-white": !isActive,
                }
              )
            }
          >
            {({ isActive }) => (
              <>
                <svg
                  className={classNames("h-6 w-6 group-hover:text-white", {
                    "text-white": isActive,
                    "": !isActive,
                  })}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4.5 12a7.5 7.5 0 0015 0m-15 0a7.5 7.5 0 1115 0m-15 0H3m16.5 0H21m-1.5 0H12m-8.457 3.077l1.41-.513m14.095-5.13l1.41-.513M5.106 17.785l1.15-.964m11.49-9.642l1.149-.964M7.501 19.795l.75-1.3m7.5-12.99l.75-1.3m-6.063 16.658l.26-1.477m2.605-14.772l.26-1.477m0 17.726l-.26-1.477M10.698 4.614l-.26-1.477M16.5 19.794l-.75-1.299M7.5 4.205L12 12m6.894 5.785l-1.149-.964M6.256 7.178l-1.15-.964m15.352 8.864l-1.41-.513M4.954 9.435l-1.41-.514M12.002 12l-3.75 6.495"
                  />
                </svg>
                <span className="mt-2">設定</span>
              </>
            )}
          </NavLink>
        </div>
      </div>
    </div>
  );
}
