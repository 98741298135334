import { AnyOwnership, OwnershipType } from "../models/Ownership";
import { filter, OwnershipFilter } from "../utils/filter";
import { useMemo } from "react";

interface Props {
  ownerships: AnyOwnership[] | undefined;
  filter?: OwnershipFilter;
  type?: OwnershipType;
}

export function useGetFilteredOwnerships({
  ownerships = [],
  filter: f = OwnershipFilter.all,
  type,
}: Props) {
  const result = useMemo(() => {
    return filter(ownerships, f, type);
  }, [ownerships, f, type]);
  return { ownerships: result };
}
