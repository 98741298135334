import { InvalidToken } from "../errors";
import axios, { AxiosResponse, Method } from "axios";

export abstract class Request<T> {
  method?: Method;
  baseUrl?: string = process.env.REACT_APP_API_ENDPOINT;
  path?: string;
  parameters?: any;
  data?: any;
  headers?: any;

  abstract parse(response: AxiosResponse): T;

  get url(): string {
    return this.baseUrl! + this.path!;
  }

  send(): Promise<T> {
    return Session.send<T, Request<T>>(this);
  }
}

class Session {
  static async send<T, S extends Request<T>>(request: S): Promise<T> {
    try {
      const data = await axios({
        method: request.method,
        baseURL: request.baseUrl,
        url: request.url,
        params: request.parameters,
        headers: request.headers,
        data: request.data,
        maxContentLength: Infinity,
      });
      return request.parse(data);
    } catch (error: any) {
      const data = error?.response?.data;
      const status = error?.response?.status;
      // NOTE: 不正なトークン時には401が返却される
      if (status === 401) {
        throw new InvalidToken();
      }
      if (data?.error?.message) {
        throw new Error(data?.error?.message);
      }
      console.error(error.message);
      throw error;
    }
  }
}
