import { HTMLDivElementProps } from "../types/element";
import classNames from "classnames";
import React from "react";

interface ErrorMessageFieldProps {}

export function ErrorMessageField({
  className,
  children,
  ...props
}: ErrorMessageFieldProps & HTMLDivElementProps) {
  return (
    <div className={classNames("rounded-md bg-red-50 p-4", className)}>
      <div {...props} className="flex">
        <div className="flex-shrink-0">
          <svg
            className="h-5 w-5 text-red-400"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
        <div className="ml-3">
          <h3 className="whitespace-pre-wrap text-sm font-semibold text-red-800">
            {children}
          </h3>
        </div>
      </div>
    </div>
  );
}
