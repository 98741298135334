import { AnyOwnership, OwnershipType } from "../models/Ownership";

export enum OwnershipStatus {
  normal,
  abnormal,
  unknown,
}

export enum OwnershipConnectionStatus {
  connected,
  disconnected,
  unknown,
}

export function getOwnershipStatus(ownership: AnyOwnership): OwnershipStatus {
  switch (ownership.type) {
    case OwnershipType.Mag: {
      const value = ownership.state?.gatt?.["strobo:1002"]?.["3000"]?.value;
      return value === true
        ? OwnershipStatus.normal
        : value === false
        ? OwnershipStatus.abnormal
        : OwnershipStatus.unknown;
    }
    case OwnershipType.Move: {
      const value = ownership.state?.gatt?.["strobo:1002"]?.["3008"]?.value;
      return value === true
        ? OwnershipStatus.normal
        : value === false
        ? OwnershipStatus.abnormal
        : OwnershipStatus.unknown;
    }
    case OwnershipType.Hub: {
      const value = ownership.state?.hat?.buzzer?.value;
      return value === true ? OwnershipStatus.abnormal : OwnershipStatus.normal;
    }
    case OwnershipType.Cam: {
      const status = ownership.state?.connection?.status;
      const value = ownership.state?.functions?.movementDetectPic?.value;
      const timestamp =
        ownership.state?.functions?.movementDetectPic?.timestamp;
      if (status !== "connected") {
        return OwnershipStatus.unknown;
      }
      if (!value || !timestamp) {
        return OwnershipStatus.normal;
      }
      const seconds =
        new Date().getTime() - new Date(timestamp * 1000).getTime();
      return seconds <= 60 * 1000
        ? OwnershipStatus.abnormal
        : OwnershipStatus.normal;
    }
    default: {
      return OwnershipStatus.unknown;
    }
  }
}

export function getOwnershipConnectionStatus(
  ownership: AnyOwnership
): OwnershipConnectionStatus {
  const status = ownership.state?.connection?.status;
  switch (status) {
    case "connected":
      return OwnershipConnectionStatus.connected;
    case "disconnected":
      return OwnershipConnectionStatus.disconnected;
    default:
      return OwnershipConnectionStatus.unknown;
  }
}
