import { BottomNavigation } from "../components/BottomNavigation";
import { Header } from "../components/Header";
import { IconsOfficeCheck } from "../components/IconsOfficeCheck";
import { Indicator } from "../components/Indicator";
import { Loader } from "../components/Loader";
import { OwnershipCell } from "../components/OwnershipCell";
import { OwnershipFilterMenu } from "../components/OwnershipFilterMenu";
import { SlideOver } from "../components/SlideOver";
import { useGetFilteredOwnerships } from "../hooks/useGetFilteredOwnerships";
import { useGetOwnerships } from "../hooks/useGetOwnerships";
import { useGetRooms } from "../hooks/useGetRooms";
import { useGetToken } from "../hooks/useKvsEnvStorage";
import { AnyOwnership, OwnershipType } from "../models/Ownership";
import { OwnershipFilter } from "../utils/filter";
import { groupByRoom } from "../utils/sort";
import Sensor from "./Sensor";
import dayjs from "dayjs";
import React, { useState, Suspense } from "react";

/**
 * TODO:
 * - Roomごとにフィルタする機能
 */
function Sensors() {
  const [item, setItem] = useState<string | undefined>(undefined);
  const [filter, setFilter] = useState<OwnershipFilter>(OwnershipFilter.all);
  const { data: token } = useGetToken();
  const { data: rooms } = useGetRooms({ value: token });
  const { isLoading, isFetching, isError, data } = useGetOwnerships({
    value: token,
  });
  const { ownerships } = useGetFilteredOwnerships({
    ownerships: data?.ownerships,
    type: OwnershipType.Mag,
    filter: filter,
  });
  const groups = groupByRoom(ownerships, rooms);
  return isLoading ? (
    <Loader className="text-green-500" />
  ) : (
    <>
      <div className="flex flex-1 flex-col overflow-hidden">
        <Header>
          <div className="flex flex-1" />
          <div className="ml-2 flex items-center space-x-4 sm:ml-6 sm:space-x-6">
            <Indicator
              isError={isError}
              isFetching={isFetching}
              target={data?.next}
              updatedAt={data?.timestamp}
            />
          </div>
        </Header>
        <div className="flex flex-1 items-stretch overflow-hidden">
          <main
            className="flex-1 overflow-y-auto"
            style={{
              paddingBottom: 130, // TODO: depend on BottomNavigation height
            }}
          >
            {ownerships.length > 0 ? (
              <div className="mx-auto max-w-7xl px-4 pt-8 sm:px-6 lg:px-8">
                <section>
                  <h2 className="sr-only">Grid View</h2>
                  <ul
                    className={
                      "grid grid-cols-3 gap-x-4 gap-y-4 sm:grid-cols-4 sm:gap-x-6 sm:gap-y-6 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 xl:gap-x-8 xl:gap-y-8"
                    }
                  >
                    {groups.map((group) => {
                      return group.ownerships.length > 0 ? (
                        <React.Fragment key={group.room.name}>
                          <li className="relative col-span-full mt-4 first:mt-0 sm:mt-2 sm:mb-[-12px] lg:mt-0 ">
                            <h2 className="text-sm font-semibold leading-tight text-[#24292f] line-clamp-2 dark:text-white md:text-base md:leading-normal ">
                              {group.room.name}{" "}
                              <span className="text-xs text-gray-500">
                                {group.ownerships.length}台
                              </span>
                            </h2>
                          </li>
                          {group.ownerships.map((ownership) => (
                            <li
                              className="relative"
                              key={ownership.serialNumber}
                            >
                              <OwnershipCell
                                item={ownership}
                                isItemSelected={item === ownership.serialNumber}
                                onItemSelected={(item) => onClick(item)}
                              />
                            </li>
                          ))}
                        </React.Fragment>
                      ) : null;
                    })}
                  </ul>
                </section>
              </div>
            ) : (
              <EmptyState
                type={filter}
                date={
                  data?.timestamp ? new Date(data.timestamp * 1000) : new Date()
                }
              />
            )}
          </main>
        </div>
      </div>
      <BottomNavigation>
        <div />
        <OwnershipFilterMenu
          type={filter}
          onFilterChange={(next) => setFilter(next)}
        />
      </BottomNavigation>
      <SlideOver open={!!item} onClose={() => onClose()}>
        <Suspense fallback={<Loader />}>
          {item ? <Sensor serialNumber={item} /> : null}
        </Suspense>
      </SlideOver>
    </>
  );

  function onClick(ownership: AnyOwnership) {
    if (item === ownership.serialNumber) {
      onClose();
    } else {
      setItem(ownership.serialNumber);
    }
  }

  function onClose() {
    setItem(undefined);
  }
}

function EmptyState({ type, date }: { type: OwnershipFilter; date: Date }) {
  return (
    <div className="mx-auto flex h-full max-w-7xl items-center px-4 pt-8 sm:px-6 lg:px-8">
      <div className="mx-auto flex max-w-2xl items-center px-6 py-16 text-center">
        <div>
          <div className="text-blue m-auto mb-3 inline-block">
            {getIcon(type)}
          </div>
          <h2 className="text-primary mb-1 text-lg font-semibold dark:text-white">
            {getTitle(type)}
          </h2>
          <div className="text-secondary mb-3">
            {getDescription(type, date)}
          </div>
        </div>
      </div>
    </div>
  );

  function getIcon(type: OwnershipFilter): React.ReactNode {
    switch (type) {
      case OwnershipFilter.all:
        return null;
      case OwnershipFilter.abnormal:
        return (
          <IconsOfficeCheck className="block shrink-0" width="64" height="64" />
        );
    }
  }

  function getTitle(type: OwnershipFilter): React.ReactNode {
    switch (type) {
      case OwnershipFilter.all:
        return <span>デバイスが登録されていません</span>;
      case OwnershipFilter.abnormal:
        return <span>すべての施錠を確認しています</span>;
    }
  }

  function getDescription(type: OwnershipFilter, date: Date): React.ReactNode {
    switch (type) {
      case OwnershipFilter.all:
        return (
          <span className="text-gray-500">
            デバイスの設置を希望する場合は、担当者までご連絡ください
          </span>
        );
      case OwnershipFilter.abnormal:
        return (
          <span className="text-gray-500">
            {dayjs(date).format("YYYY/MM/DD HH:mm:ss")}の時点で
            <br />
            確認が必要な設置箇所はありません
          </span>
        );
    }
  }
}

export default Sensors;
