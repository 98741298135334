import { HTMLDivElementProps } from "../types/element";
import { OwnershipFilter } from "../utils/filter";
import { IconsMiniCheck } from "./IconsMiniCheck";
import { IconsMiniChevronUp } from "./IconsMiniChevronUp";
import { Menu, Transition } from "@headlessui/react";
import classNames from "classnames";
import React, { Fragment } from "react";

interface Props {
  type: OwnershipFilter;
  onFilterChange: (type: OwnershipFilter) => void;
}

export function OwnershipFilterMenu({
  type,
  onFilterChange,
  className,
}: Props & HTMLDivElementProps) {
  return (
    <div className={classNames(className)}>
      <label id="listbox-label" className="sr-only">
        Change status
      </label>
      <div className="relative">
        <Menu>
          <div
            className={classNames("inline-flex divide-x rounded-md shadow-sm", {
              "divide-indigo-600": type === OwnershipFilter.all,
              "divide-red-600": type === OwnershipFilter.abnormal,
            })}
          >
            <Menu.Button
              as="div"
              className={classNames(
                "inline-flex divide-x rounded-md shadow-sm",
                {
                  "divide-indigo-600": type === OwnershipFilter.all,
                  "divide-red-600": type === OwnershipFilter.abnormal,
                }
              )}
            >
              <div
                className={classNames(
                  "inline-flex cursor-pointer items-center rounded-l-md border border-transparent py-2 pl-3 pr-4 text-white shadow-sm",
                  {
                    "bg-indigo-500 hover:bg-indigo-600":
                      type === OwnershipFilter.all,
                    "bg-red-600 hover:bg-red-700":
                      type === OwnershipFilter.abnormal,
                  }
                )}
              >
                {/* Heroicon name: mini/check */}
                <svg
                  className="h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                    clipRule="evenodd"
                  />
                </svg>
                <p className="ml-2.5 text-sm font-medium">{getTitle(type)}</p>
              </div>
              <Menu.Button
                type="button"
                className={classNames(
                  "inline-flex items-center rounded-l-none rounded-r-md p-2 text-sm font-medium text-white focus:outline-none",
                  {
                    "bg-indigo-500 hover:bg-indigo-600":
                      type === OwnershipFilter.all,
                    "bg-red-600 hover:bg-red-700":
                      type === OwnershipFilter.abnormal,
                  }
                )}
                aria-haspopup="listbox"
                aria-expanded="true"
                aria-labelledby="listbox-label"
              >
                <span className="sr-only">表示するデバイスをフィルタ</span>
                {/* Heroicon name: mini/chevron-up */}
                <IconsMiniChevronUp className="h-5 w-5 text-white" />
              </Menu.Button>
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Menu.Items
              as="ul"
              className="absolute right-0 -top-4 z-10 mt-2 w-72 origin-top-right -translate-y-full transform divide-y divide-gray-200 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:divide-gray-800"
            >
              {/*
                    Select option, manage highlight styles based on mouseenter/mouseleave and keyboard navigation.
                    Highlighted: "text-white bg-indigo-500", Not Highlighted: "text-gray-900"
                    */}
              <Menu.Item>
                {({ active }) => (
                  <li
                    className={classNames(
                      "cursor-pointer select-none p-4 text-sm text-white",
                      {
                        "bg-indigo-500": !active,
                        "bg-indigo-600": active,
                      }
                    )}
                    onClick={() => onFilterChange(OwnershipFilter.all)}
                  >
                    <div className="flex flex-col">
                      <div className="flex justify-between">
                        <p className="font-semibold">すべてのデバイスを表示</p>
                        <span
                          className={classNames("text-white", {
                            hidden: type !== OwnershipFilter.all,
                          })}
                        >
                          {/* Heroicon name: mini/check */}
                          <IconsMiniCheck className="h-5 w-5" />
                        </span>
                      </div>
                      {/* Highlighted: "text-indigo-200", Not Highlighted: "text-gray-500" */}
                      <p className="mt-2 text-indigo-200">
                        設置したすべてのデバイスを表示します。
                      </p>
                    </div>
                  </li>
                )}
              </Menu.Item>
              {/*
                    Select option, manage highlight styles based on mouseenter/mouseleave and keyboard navigation.
                    Highlighted: "text-white bg-indigo-500", Not Highlighted: "text-gray-900"
                    */}
              <Menu.Item>
                {({ active }) => (
                  <li
                    className={classNames(
                      "cursor-pointer select-none p-4 text-sm text-white",
                      {
                        "bg-red-600": !active,
                        "bg-red-700": active,
                      }
                    )}
                    onClick={() => onFilterChange(OwnershipFilter.abnormal)}
                  >
                    <div className="flex flex-col">
                      <div className="flex justify-between">
                        <p className="font-semibold">要確認のデバイスを表示</p>
                        <span
                          className={classNames("text-white", {
                            hidden: type !== OwnershipFilter.abnormal,
                          })}
                        >
                          {/* Heroicon name: mini/check */}
                          <IconsMiniCheck className="h-5 w-5" />
                        </span>
                      </div>
                      {/* Highlighted: "text-indigo-200", Not Highlighted: "text-gray-500" */}
                      <p className="mt-2 text-red-200">
                        施錠や現在の状態が確認できないデバイスのみを表示します。
                      </p>
                    </div>
                  </li>
                )}
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </div>
  );

  function getTitle(type: OwnershipFilter): string {
    switch (type) {
      case OwnershipFilter.all:
        return "すべてのデバイスを表示";
      case OwnershipFilter.abnormal:
        return "要確認のデバイスを表示";
    }
  }
}
