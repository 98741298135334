import { AnyOwnership } from "../models/Ownership";
import { HTMLDivElementProps } from "../types/element";
import {
  getOwnershipConnectionStatus,
  getOwnershipStatus,
  OwnershipConnectionStatus,
  OwnershipStatus,
} from "../utils/status";
import { OwnershipIcon } from "./OwnershipIcon";
import { OwnershipStatusIcon } from "./OwnershipStatusIcon";
import { OwnershipStatusText } from "./OwnershipStatusText";
import { OwnershipTypeText } from "./OwnershipTypeText";
import classNames from "classnames";
import React from "react";

interface Props {
  item: AnyOwnership;
  isItemSelected?: boolean;
  onItemSelected?: (item: AnyOwnership) => void;
}

enum Background {
  green,
  red,
  gray,
}

export function OwnershipCell({
  onItemSelected,
  isItemSelected,
  item,
}: Props & HTMLDivElementProps) {
  const bg = getBackground();

  // Current: "ring-2 ring-offset-2 ring-indigo-500", Default: "focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500"
  return (
    <div
      className={classNames(
        "group aspect-w-10 aspect-h-10 block w-full overflow-hidden rounded-xl focus-within:ring-offset-gray-100",
        {
          "bg-leafee-green focus-within:ring-leafee-green dark:bg-leafee-green-dark":
            bg === Background.green,
          "bg-leafee-orange focus-within:ring-leafee-orange":
            bg === Background.red,
          "bg-primary-gray focus-within:ring-primary-gray":
            bg === Background.gray,
          "ring-2 ring-leafee-green ring-offset-2":
            isItemSelected && Background.green,
          "ring-2 ring-leafee-orange ring-offset-2":
            isItemSelected && Background.red,
          "ring-2 ring-primary-gray ring-offset-2":
            isItemSelected && Background.gray,
          "focus-within:ring-2 focus-within:ring-offset-2": !isItemSelected,
        }
      )}
    >
      <div>
        <div className="absolute top-0 left-0 right-0 text-white">
          <div className="flex justify-between">
            <OwnershipIcon
              className="mt-[8px] ml-[8px] mb-[3px] h-6 w-6 opacity-20 sm:h-6 sm:w-6 md:h-8 md:w-8 lg:h-10 lg:w-10"
              item={item}
            />
            <OwnershipStatusIcon
              className="mt-[8px] mr-[8px] mb-[3px] h-6 w-6 sm:h-8 sm:w-8 md:h-8 md:w-8 lg:h-10 lg:w-10"
              item={item}
            />
          </div>
          <p className="-mt-1 mb-[6px] px-[8px] text-[10px] sm:mt-0 md:text-sm">
            <OwnershipTypeText type={item.type} />
          </p>
        </div>
        <div className="absolute bottom-0 left-0 right-0 text-white">
          <p className="mb-[6px] px-[8px] text-sm leading-tight line-clamp-2 md:text-base md:leading-normal">
            {item.name}
          </p>
          <p className="bg-black bg-opacity-10 px-[8px] py-[4px] text-[10px] sm:text-sm">
            <OwnershipStatusText item={item} />
          </p>
        </div>
      </div>
      {/* Current: "", Default: "group-hover:opacity-75" */}
      <button
        type="button"
        className={classNames("absolute inset-0 bg-black focus:outline-none", {
          "bg-opacity-0 group-hover:bg-opacity-10": !isItemSelected,
          "bg-opacity-10": isItemSelected,
        })}
        onClick={() => onItemSelected?.(item)}
      >
        <span className="sr-only">{item.name}</span>
      </button>
    </div>
  );

  function getBackground(): Background {
    const status = getOwnershipConnectionStatus(item);
    if (status !== OwnershipConnectionStatus.connected) {
      return Background.gray;
    }
    switch (getOwnershipStatus(item)) {
      case OwnershipStatus.normal:
        return Background.green;
      case OwnershipStatus.abnormal:
        return Background.red;
      case OwnershipStatus.unknown:
        return Background.gray;
    }
  }
}
