import { AnyOwnership } from "../models/Ownership";
import { HTMLDivElementProps } from "../types/element";
import {
  getOwnershipConnectionStatus,
  OwnershipConnectionStatus,
} from "../utils/status";
import React from "react";

interface Props {
  item: AnyOwnership;
}

export function OwnershipStatusIcon({
  className,
  item,
}: Props & HTMLDivElementProps) {
  const status = getOwnershipConnectionStatus(item);
  if (status === OwnershipConnectionStatus.disconnected) {
    return (
      <svg
        className={className}
        width="16"
        height="20"
        viewBox="0 0 16 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.941 4C11.2083 4 10.6321 4.62621 10.693 5.35635L11.1788 11.1868C11.2171 11.6465 11.6014 12 12.0626 12C12.538 12 12.9289 11.6251 12.9487 11.1501L13.1923 5.30448C13.2219 4.59299 12.6531 4 11.941 4ZM12 16C11.2636 16 10.6667 15.403 10.6667 14.6667C10.6667 13.9303 11.2636 13.3333 12 13.3333C12.7364 13.3333 13.3334 13.9303 13.3334 14.6667C13.3334 15.403 12.7364 16 12 16Z"
          fill="white"
        />
      </svg>
    );
  } else {
    return null;
  }
}
