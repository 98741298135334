import { useTimer } from "../hooks/useTimer";
import { HTMLDivElementProps } from "../types/element";
import React from "react";
import { Menu, Transition } from "@headlessui/react";
import { IconsArrowPath } from "./IconsArrowPath";
import { formatTimestamp } from "../utils/date";

const circumference = 15 * 2 * Math.PI;

interface Props {
  isFetching: boolean;
  isError: boolean;
  target?: number;
  updatedAt?: number;
}

export function Indicator({
  isFetching,
  isError,
  target,
  updatedAt,
}: Props & HTMLDivElementProps) {
  const { data } = useTimer(target);
  if (!data) {
    return null;
  }
  return (
    <div className="relative inline-flex items-center justify-center">
      <svg className="h-[38px] w-[38px]">
        <circle
          className="text-gray-100 dark:text-[#757779]"
          strokeWidth="3"
          stroke="currentColor"
          fill="transparent"
          r="15"
          cx="19"
          cy="19"
        />
        {target && !isFetching && !isError && (
          <Progress percent={data.percent} />
        )}
      </svg>
      <span className="absolute text-sm text-gray-300 dark:text-[#757779]">
        {isError ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            className="h-5 w-5 text-yellow-500"
          >
            <path
              fillRule="evenodd"
              d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z"
              clipRule="evenodd"
            />
          </svg>
        ) : isFetching ? (
          <IconsArrowPath className="h-5 w-5 animate-spin" />
        ) : (
          <div className="font-semibold">{data.label}</div>
        )}
      </span>
      <Menu>
        <Menu.Button
          className={
            "absolute inset-0 rounded-full bg-black bg-opacity-0 focus:outline-none"
          }
        >
          <span className="sr-only">indicator</span>
        </Menu.Button>
        <Transition
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
        >
          <Menu.Items className="absolute right-0 z-10 mt-5 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:divide-gray-600 dark:bg-[#24292f]">
            <Menu.Item>
              <div className="px-4 py-3">
                <p
                  className="text-sm text-gray-700 dark:text-gray-200"
                  role="none"
                >
                  最終更新日時
                </p>
                <p className="truncate text-sm text-gray-400">
                  {updatedAt ? formatTimestamp(updatedAt) : "未取得"}
                </p>
              </div>
            </Menu.Item>
            <Menu.Item>
              <div className="py-1">
                <button
                  className="block flex w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-200 dark:hover:bg-gray-800"
                  onClick={() => reload()}
                >
                  <IconsArrowPath className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 dark:text-gray-200" />
                  強制的にリロードする
                </button>
              </div>
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
  function reload() {
    window.location.reload();
  }
}

function Progress({ percent }: { percent: number }) {
  return (
    <circle
      className="text-gray-300 dark:text-[#C1C1C1]"
      strokeWidth="3"
      strokeLinecap="round"
      stroke="currentColor"
      strokeDasharray={circumference}
      strokeDashoffset={circumference - percent * circumference}
      fill="transparent"
      r="15"
      cx="19"
      cy="19"
    />
  );
}
