import { Token } from "../models/Token";
import { GetOwnerships, OwnershipsResponse } from "../networks/GetOwnerships";
import { delay, refetchInterval } from "../utils/delay";
import { QueryFunction, useQuery } from "@tanstack/react-query";

export function useGetOwnerships(token: Token) {
  return useQuery<OwnershipsResponse>(
    ["ownerships", token],
    queryGetOwnerships(token),
    {
      retry: false,
      refetchInterval: refetchInterval,
    }
  );
}

let isInterval = false;

function queryGetOwnerships(token: Token): QueryFunction<OwnershipsResponse> {
  return async () => {
    if (!token.value) {
      throw new Error("not token");
    }
    if (isInterval) {
      await delay(1500);
    } else {
      isInterval = true;
    }
    return await new GetOwnerships(`${token.value}`).send();
  };
}
