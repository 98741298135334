import React from "react";

interface Props {
  type: string;
}

export function OwnershipTypeText({ type }: Props) {
  switch (type) {
    case "mag":
      return <span>開閉センサー</span>;
    case "cam":
      return <span>カメラ</span>;
    case "move":
      return <span>人感センサー</span>;
    case "hub":
      return <span>ホームハブ</span>;
    default:
      return <span>不明なデバイス</span>;
  }
}
