import { HTMLDivElementProps } from "../types/element";
import classNames from "classnames";
import React from "react";
import { useOutletContext } from "react-router-dom";

interface Props {}

export function Header({ className, children }: Props & HTMLDivElementProps) {
  const [isSidebarOpen, setIsSidebarOpen] =
    useOutletContext<[boolean, (next: boolean) => void]>();

  return (
    <header
      className={classNames(
        "w-full bg-white pt-safe dark:bg-[#1B1D21]",
        className
      )}
    >
      <div className="relative z-10 flex h-16 flex-shrink-0 border-b border-gray-200 bg-white shadow-sm dark:border-[#2C343B] dark:bg-[#1B1D21]">
        <button
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
          type="button"
          className="px-4 text-gray-500 focus:outline-none dark:text-white md:hidden"
        >
          <span className="sr-only">Open sidebar</span>
          {/* Heroicon name: outline/bars-3-bottom-left */}
          <svg
            className="h-6 w-6"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25H12"
            />
          </svg>
        </button>
        <div className="flex flex-1 justify-between px-4 sm:px-6">
          {children}
        </div>
      </div>
    </header>
  );
}
