import { AnyOwnership, OwnershipType } from "../models/Ownership";
import {
  getOwnershipConnectionStatus,
  getOwnershipStatus,
  OwnershipConnectionStatus,
  OwnershipStatus,
} from "./status";

export enum OwnershipFilter {
  all,
  abnormal,
}

export function filter(
  ownerships: AnyOwnership[],
  filter: OwnershipFilter,
  type?: OwnershipType
) {
  const items = type
    ? ownerships.filter((item) => item.type === type)
    : ownerships;
  switch (filter) {
    case OwnershipFilter.all: {
      return items;
    }
    // NOTE:- センサーの反応状態が "normalではない" または "接続中ではない" デバイス
    case OwnershipFilter.abnormal: {
      return items.filter(
        (ownership) =>
          getOwnershipStatus(ownership) !== OwnershipStatus.normal ||
          getOwnershipConnectionStatus(ownership) !==
            OwnershipConnectionStatus.connected
      );
    }
  }
}
